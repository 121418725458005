import React, { useState, useEffect } from 'react';
import * as styles from './EmailListForm.module.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        alert(data.result);
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.error(error);
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  useEffect(() => {
    // Dynamically load the Buy Me a Coffee button script
    const script = document.createElement('script');
    script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js';
    script.setAttribute('data-name', 'bmc-button');
    script.setAttribute('data-slug', 'thedatabus');
    script.setAttribute('data-color', '#FFDD00');
    script.setAttribute('data-emoji', '');
    script.setAttribute('data-font', 'Cookie');
    script.setAttribute('data-text', 'Buy me a coffee');
    script.setAttribute('data-outline-color', '#000000');
    script.setAttribute('data-font-color', '#000000');
    script.setAttribute('data-coffee-color', '#ffffff');
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.EmailListFormContainer}>
      <form onSubmit={handleSubmit} className={styles.EmailListForm}>
        <strong>
          Like what you are reading? Let me send you updates on posts and courses right to your inbox!
        </strong>
        <div className={styles.Wrapper}>
          <input
            placeholder="Email"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
          <button type="submit">Subscribe</button>
        </div>
        <p>
        <strong>
        If you wish see more of this content more often. 
        You should 
        <button>
  <a href="https://www.buymeacoffee.com/thedatabus"
     class="coffee-button">BUY ME A COFFEE</a>
        </button>
        </strong>
        </p>
      </form>
      {/* Buy Me a Coffee Button */}
      <div className={styles.BuyMeACoffeeWrapper}>
        <div id="bmc-button-container" />
      </div>
    </div>
  );
};

export default EmailListForm;

