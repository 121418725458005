import React, { FunctionComponent, useEffect } from "react";
import { MenuItem } from "../../utils/models";
import {
  Copyright,
  DesignBy,
  FooterContainer,
  FooterMenuItem,
  FooterMenuLink,
  StyledFooter,
  StyledNav,
  BuyMeACoffeeContainer, // New styled component
} from "./style";

interface FooterProps {
  menu: MenuItem[];
  owner: string;
}

const Footer: FunctionComponent<FooterProps> = ({ menu, owner }) => {
  useEffect(() => {
    // Dynamically load the Buy Me a Coffee button script
    const script = document.createElement("script");
    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js";
    script.setAttribute("data-name", "bmc-button");
    script.setAttribute("data-slug", "thedatabus");
    script.setAttribute("data-color", "#FFDD00");
    script.setAttribute("data-emoji", "");
    script.setAttribute("data-font", "Cookie");
    script.setAttribute("data-text", "Buy me a coffee");
    script.setAttribute("data-outline-color", "#000000");
    script.setAttribute("data-font-color", "#000000");
    script.setAttribute("data-coffee-color", "#ffffff");
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StyledFooter>
      <FooterContainer>
        <StyledNav>
          <ul>
            {menu.map((item, index) => (
              <li key={index}>
                {["/rss.xml", "/sitemap.xml"].indexOf(item.path) >= 0 ? (
                  <FooterMenuItem href={item.path} rel={`noopener noreferrer`}>
                    {item.name}
                  </FooterMenuItem>
                ) : (
                  <FooterMenuLink to={item.path}>{item.name}</FooterMenuLink>
                )}
              </li>
            ))}
          </ul>
        </StyledNav>
        <div>
          <Copyright>
            <strong>{owner}</strong>&nbsp;&copy; {new Date().getFullYear()}
          </Copyright>
        </div>
      </FooterContainer>
      <BuyMeACoffeeContainer>
        {/* The Buy Me a Coffee button will be rendered dynamically by the script */}
        <div id="bmc-button-container" />
      </BuyMeACoffeeContainer>
    </StyledFooter>
  );
};

export default Footer;

